.dashboard {
  padding-top: 10%;
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 10%;
  width: 100%;
  display:grid;
  direction: rtl;
  @media only screen and (max-width: 991px) {
    padding-top: 17%;
  }
  @media only screen and (max-width: 300px) {
    padding-top: 20%;
  }
  &-profile {
   
    width: 40%;
    justify-self:left;
    margin-bottom: -5%;
    height: 150px;
    text-align: center;
    align-items: center;
    justify-content:left;
    font-size: 20px;
    font-weight: 700;
    @media only screen and (max-width: 991px) {
      height: 100px;
      margin-right: 35%;
      @media only screen and (max-width: 320px) {
        margin-right: 30%;
      }
    }
    .emailSpan {
      text-align: left !important;
      @media only screen and (max-width: 991px) {
        margin-top: 15px;
        font-size: 12px;
      }
    }
    .nameSpan {
      text-align: left !important;
      color: #a1813a;
      padding-top: 10px;
      font-size: 16px !important;
      @media only screen and (max-width: 991px) {
        font-size: 10px !important;
      }
    }
    .profileSpan {
      font-size: 14px;
      cursor: pointer;
      @media only screen and (max-width: 991px) {
        font-size: 10px;
      }
    }
    .menuIcon {
      @media only screen and (max-width: 320px) {
        margin-left: 0 !important;
      }
    }
    .mobile-tab {
      position: absolute;
      top: 140px;
      right: 60px;
      width: 50%;
      gap: 5px;

      @media only screen and (min-width: 500px) {
        top: 23vh;
        width: 35%;
        right: 27%;
        @media only screen and (min-width: 770px) {
          top: 18vh;
          width: 30%;
          right: 30%;
        }
      }
    }
    > div > img {
      max-width: 100px;
      @media only screen and (max-width: 991px) {
        max-width: 50px;
      }
    }
  }
}
.divider {
  width: 102%;
  height: 1px;
  background-color: #d1d1d1;
  margin-top: 4%;
}
.react-tabs__tab--selected {
  background-color: #f2f2f2;
  border-color: #d1d1d1;
  padding: 18px;
}
.react-tabs__tab {
  font-size: 16px;
  font-weight: 600;
  padding: 18px;
}
