.forget {
  align-items: center;

  &-header {
    height: 100px;
    width: 30%;
    & > img {
      width: 10%;
    }
    & > a {
      width: 75%;
      & > img {
        padding-top: 5%;
        width: 100%;
      }
    }
    @media only screen and (max-width: 991px) {
      width: 65%;
    }
    @media only screen and (max-width: 400px) {
      width: 100%;
    }
  }
  &-box {
    width: 40vw;
    height: 50vh;
    position: absolute;
    top: 20vh;
    left: 30vw;
    background: #ffffff;
    box-shadow: 0px 4px 10px rgba(225, 225, 225, 0.6);
    border-radius: 4px;
    box-sizing: border-box;
    padding-right: 3rem !important;
    padding-left: 3rem !important ;
    @media only screen and (max-width: 991px) {
      width: 100vw;
      left: 0;
      padding-right: 0.5rem !important;
      padding-left: 0.5rem !important ;
      box-shadow: none;
    }

    > h2 {
      font-size: 40px;
      font-weight: 700;
      line-height: 45px;
      text-align: center;
      @media only screen and (max-width: 991px) {
        font-size: 35px;
        line-height: 40px;
      }
      @media only screen and (max-width: 400px) {
        font-size: 25px;
        line-height: 30px;
      }
    }
    > h3 {
      font-size: 18px;
      font-weight: 400;
      line-height: 25px;
      text-align: center;
      @media only screen and (max-width: 991px) {
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
}
.disable {
  color: gray;
}
.enable {
  color: #a1813a;
  cursor: pointer;
}
