.profile {
  width: 100% !important;
  overflow-x: hidden;
  //   height: 200vw;
  overflow-y: hidden !important;
  &-box {
    height: 100%;
    padding-bottom: 20%;
    width: 85%;
    @media only screen and (max-width: 991px) {
      width: 100%;
    }
    &-header {
      height: 80px;
      width: 60%;
      @media only screen and (max-width: 991px) {
        width: 100%;
      }
      & > img {
        width: 10%;
      }
      & > a {
        width: 75%;
        & > img {
          padding-top: 5%;
          width: 100%;
        }
      }
    }
    > h2 {
      font-size: 40px;
      font-weight: 700;
      line-height: 45px;
      text-align: center;
    }
    > h3 {
      font-size: 14px;
      font-weight: 400;
      line-height: 25px;
      text-align: center;
      & > a {
        color: #a1813a !important;
        text-decoration: none;
      }
    }
    & > div > a {
      padding-left: 50%;
      color: #18324e !important;
      font-size: 14px;
      font-weight: bold;
    }
  }
  &-img {
    background-image: url("../../assets/images/SignUpBG.png");
    background-size: cover;

    & > div {
      position: absolute;
      top: 10vh;
      left: 58vw;
      width: 35vw;
      height: 30vh;
      backdrop-filter: blur(2px);
      border-radius: 4px;
      background-color: rgb(255, 255, 255, 0.8);
      text-align: center;
      & > h3 {
        font-size: 24px;
        line-height: 28px;
      }
      & > h2 {
        font-size: 30px;
        line-height: 40px;
        color: #18324e;
      }
    }
  }
}
