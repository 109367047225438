.services1{
    margin-top: 3rem;
    >button{
        border: none;
        background-color: transparent;
        color: black;
        font-weight: 500;
        font-size: 18px;
        text-align: center;
        padding-bottom: 10px;
        
    }
    >button:hover{
        
        border-bottom: 1.7px solid rgba(161, 129, 58, 1);
    }
    .active{
        font-weight: bold;
        border-bottom: 1.7px solid rgba(161, 129, 58, 1);
    }
}
.servic-card{
    align-self: center;
    height: 400px;
    background-color: #ffff;
    box-shadow: 0px 4px 10px 0px rgba(225, 225, 225, 0.4);
border-radius: 10px;
h2{
  
font-size: 22px;
font-weight: 700;
line-height: 26px;
letter-spacing: 0em;
text-align: left;

}
h4{
   color: rgba(0, 0, 0, 0.4);
font-size: 16px;
font-weight: 400;
line-height: 19px;
text-align: left;
}
h3{
    color: rgba(158, 158, 158, 1);
font-size: 14px;
font-weight: 400;
text-align: left;


}
}
.react-multi-carousel-list{
    max-width:950px;
    height: 480px;
    position: unset !important;
    margin: 0 2rem;
}
.react-multiple-carousel__arrow--right {
    right: calc(8% + 1px) !important;
}
.react-multiple-carousel__arrow--left {
    left: calc(6% + 1px) !important;
}
.react-multi-carousel-dot-list{
    position:  relative !important;
}
.bar{
    background: rgba(240, 240, 240, 0.53);
    border: 1px solid rgba(240, 240, 240, 0.53);
    border-radius: 30px;
    width: 100%;
    height: 12px;
    .complate{
        height: 12px;
        width: 57%;
        border-radius: 30px;
        background: rgba(24, 50, 78, 0.4);
    }
}
.service-page{
    padding-right: 20px;
    >h2{
        font-size: 30px;
        font-weight: 700;

    }
    >h3{
       color: rgba(0, 0, 0, 0.4);
       font-size: 16px;
       font-weight: 700;

    }
    >h4{
font-size: 20px;
font-weight: 700;
line-height: 24px;
text-align: left;

    }
    >p{
        margin-top: 15px;
        font-weight: 400;
font-size: 16px;
line-height: 19px;
display: flex;
align-items: center;
    }
}
.v-line{
    height: 27px;
    width: 1px;
    background-color: rgba(158, 158, 158, 0.53);
}
.desc-box{
box-shadow: 0px 0px 10px rgba(225, 225, 225, 0.7);
border-radius: 2px;
padding: 15px;
font-weight: 400;
font-size: 12px;
line-height: 16px;
color: #000000;
margin: -8px 0 15px 1.5rem;
width: 87%;
}
.step-box{
    box-shadow: 0px 0px 10px 0px rgba(225, 225, 225, 0.7);
    border-radius: 10px;
   
    margin: 8px 35px 8px 10px;
    align-items: center;
    padding-left: 25px;
    .step-title{
color:rgba(24, 50, 78, 1);
font-weight: 700;
font-size: 16px;
    }
}
.title
    {
        color: rgba(24, 50, 78, 1);
font-size: 20px;
font-weight: 700;
line-height: 24px;

    }
    .react-multi-carousel-dot--active button{
 background: rgba(161, 129, 58, 1) !important;

    }
    .react-multi-carousel-dot button{
        border-color: transparent !important;
        background: rgba(217, 217, 217, 1) ;

    }
    .reply-box{
        position: absolute;
        left: 61%;
        border-radius: 4px;
    border: 1px solid var(--light-grey, #D1D1D1);
    background: #FFF;
    z-index: 4;

    h3{
        color: #000;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px; 
    }
    h4{
        color: #2E6CDF;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px; /* 123.077% */
    }
    p{padding-left: 10px;
        padding-top: 15px;
        padding-bottom: 15px;
        color: #4C4C4C;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 133.333% */
    }
    h5{
        color: #9E9E9E;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    span{
        cursor: pointer;
        color: #000;
        font-size: 11px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px; /* 145.455% */
    }
    }

    .fixButton{
        position:absolute;
        bottom: 35px;
        width: 73% !important;
    }

.h-line{
width: 100%;
height: 1px;
background-color: rgba(158, 158, 158, 0.53);
margin-top: 7px;
margin-bottom: 15px;
}