.appointments {
  > h1 {
    font-size: 20px;
    font-weight: 700;
    text-align: left;
    @media only screen and (max-width: 991px) {
      font-size: 12px;
    }
  }
  &_row{
    flex-direction: row;
    @media only screen and (max-width: 991px) {
      flex-direction: column;
    }
  }
}

.appointment {
  height: 200px;
  border: 1px solid #d1d1d1;
  border-left: none;
  border-right: none;
  background-color: #fefbe1;
  @media only screen and (max-width: 991px) {
    height: 220px;
    width: 100% !important;
    padding-right: 0 !important;
  }

  > h2 {
    font-size: 18px;
    font-weight: bold;

    line-height: 26px;
    text-align: left;
    color: #18324e;
    @media only screen and (max-width: 991px) {
      font-size: 12px;
      line-height: 16px;
    }
  }
  > h3 {
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    text-align: left;
    @media only screen and (max-width: 991px) {
      font-size: 10px;
      line-height: 14px;
    }
  }
}
.urls {
  > a {
    font-size: 14px;
    text-decoration: none;
    color: #18324e;
    @media only screen and (max-width: 991px) {
      font-size: 10px;
    }
  }
}
.date {
  font-size: 16px;
  font-weight: bold;
  color: #18324e;
  @media only screen and (max-width: 991px) {
    font-size: 10px;
    padding-right: 0 !important;
  }
}
.enter {
  font-size: 18px;
  font-weight: 500;
  color: #18324e;
  line-height: 32px;
  text-decoration: none;
  align-items: center;
  gap: 10px;
  @media only screen and (max-width: 991px) {
    font-size: 12px;
    line-height: 20px;
  }
}
