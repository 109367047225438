.documents {
  > div > h1 {
    font-size: 24px;
    font-weight: bold;
    @media only screen and (max-width: 991px) {
      font-size: 18px;
    }
  }
  > div > p {
    line-height: 22px;
    font-size: 16px;
    @media only screen and (max-width: 991px) {
      font-size: 12px;
      line-height: 16px;
    }
  }
  > div > h2 {
    color: #18324e;
    font-size: 21px;
    font-weight: bold;
    @media only screen and (max-width: 991px) {
      font-size: 16px;
    }
  }
  > div > div > div > div > h2 {
    color: #18324e;
    font-size: 18px;
    font-weight: bold;
    @media only screen and (max-width: 991px) {
      font-size: 12px;
    }
  }
  > div > div > div > div > h3 {
    color: #d1d1d1;
    font-size: 18px;
    padding-left: 10px;
    @media only screen and (max-width: 991px) {
      font-size: 12px;
      padding-left: 7px;
    }
  }
}
.line {
  width: 100%;
  height: 1.5px;
  background-color: #d1d1d1;
  margin-bottom: 15px;
  @media only screen and (max-width: 991px) {
    height: 1px;
    margin-bottom: 15px;
  }
}
.myModal {
  width: 40vw;
  height: 70vh;
  background: #ffffff;
  position: absolute;
  top: 15vh;
  left: 30vw;
  z-index: 5 !important;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 1.5rem;
  padding-bottom: 2rem;
  overflow-y: scroll;
  @media only screen and (max-width: 991px) {
    width: 70vw;
    left: 15vw;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  @media only screen and (max-width: 400px) {
    width: 90vw;
    left: 5vw;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  > div > h1 {
    font-size: 28px;
    font-weight: 700;
    @media only screen and (max-width: 991px) {
      font-size: 24px;
    }
  }
  > div > p {
    font-size: 14px;
    font-weight: 400;
    margin-top: 15px;
    margin-bottom: 15px;
    @media only screen and (max-width: 991px) {
      font-size: 12px;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
}

.ewqTBN {
  height: 70% !important;
  justify-content: center !important;
  align-items: center !important;
}
.kyUZVo {
  box-sizing: content-box !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 25px;
  @media only screen and (max-width: 991px) {
    gap: 15px;
  }
}

table{
  .category{
font-weight: 700;
color: #18324e;
@media only screen and (max-width: 991px) {
  font-weight: 400;
}
  }

  text-align: left;
  td,th{
    padding:1.5rem 1rem;
    @media only screen and (max-width: 991px) {
      padding: 0.5rem 0.5rem;
    }
  }
  th{
font-size: 16px;
font-weight: 700;
line-height: 19px;
color: black;
@media only screen and (max-width: 991px) {
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
}

  }
  td{
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    @media only screen and (max-width: 991px) {
      font-weight: 300;
      font-size: 8px;
      line-height: 10px;
    }
  }
  tr{
    box-sizing: border-box;
    align-items: center;
  }
  .last{
    
      border-bottom: #d1d1d1 1px solid;
    
  }
}
